.login {
	display: flex;
	justify-content: center;
	padding-top: 2em ;
	min-width: 40% ;
	flex-grow: 1;
}

.login-form-con {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.login-form-con  button {
	min-height: 8vh ;
	font-size:  1.2rem ;
	font-family: var(--font);
}