.downbar {	
	font-weight: bold ;
	font-family: var(--font);
	text-align: center;
	color: var(--black);
	font-size: .8rem ;
}

.downbar p {	
	margin: .2em .2em .4em ;
}

.myarth {	
	width: 10vw ;
	cursor: pointer;
}

@media(max-width: 600px)
{	
	.downbar {	
		font-size: .6rem ;
	}

	.downbar p {	
		margin: .2em .2em .4em ;
	}

	.myarth {	
		width: 25vw ;
	}
}