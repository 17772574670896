.intro-main {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 1em .5em ;
	font-family: var(--font);
	color: var(--white);
	background-color: var(--dark);
}

.intro-main img {
	height: 300px;
	border-radius: 1rem ;
}

.intro-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
}

.intro-content h1 {
	font-size: 2rem;
	color: var(--homeGreen);
}

.message-main {
	color: red ;
	font-size: 1.2rem ;
}

@media(max-width: 600px)	{
	.intro-main {
		display: flex;
		flex-direction: column-reverse;
		padding: 1em .5em ;
	}

	.intro-main img {
		height: 200px;
	}

	.intro-content {
		padding: 1em 0 0 0;
		text-align: center;
	}

	.intro-content h1 {
		font-size: 1.5rem;
	}

	.intro-content p {
		margin-bottom: 0;
	}
}