.scrolltopbar   {
  position: fixed;
  z-index: 15;
  left:0;
  top:0;
  background-color: var(--white);
  width:100%;
  /*text-align:center;*/
  /*font-size:22px;*/
  /*box-shadow: 0px 4px 4px rgba(0,0,0,0.2);*/
}
 
.fade-in {
  -webkit-animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.name-size {
  font-size: 1.7rem ;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}