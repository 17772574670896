@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap');

html, #root {
  height: 100%;
}

body {
  margin: 0;
  height: 100% ;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --main: rgb(70, 130, 180) ;
  --dark: rgb(29, 41, 81) ;
  --light: rgb(83, 154, 212) ;
  --darker: rgb(9, 21, 41) ;
  --white: white ;
  --dusraWhite: #f7f7f9;
  --black: black ;
  --gray: gray;
  --lightGray: lightgray ;
  --golden: rgb(212, 170, 32);
  --lightBlue: rgba(187, 223, 252);
  --darkLightBlue: rgba(164, 213, 252);
  --homeGreen: #05f545;

  --font: 'Lexend', sans-serif ;
}