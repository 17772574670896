.list  {	
	font-weight: normal; 
	margin: 0;
}

.list ul {
	margin-top: .2em ;
	padding-inline-start: 10px;
}

.list-li {
	margin: 0.3em 0 ;
	font-size: 1rem ;
	font-family: var(--font);
}

@media(max-width: 600px)
{	.list  {	
		margin: 0 ;
	}

	.list ul {
		margin: 0 ;
		/*list-style-type: none ;*/
		padding: .5em 2em ;
	}

	.list-li {
		font-size: .8rem ;
		text-align: left;
	}
}