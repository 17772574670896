.planner {
	width: 100% ;
	flex-grow: 1;
}

.planner-con {
	display: flex;
	justify-content: center;
	font-size: 1rem ;
	font-family: var(--font);
	padding: .3em 2em ;
	text-align: center;
}

.planner-clinic {
	display: flex;
	justify-content: space-around;
	padding: 1em 0 ;
}

.check-appointment {
	padding-left: 2em;
}

.sched-btn {
	background-color: var(--dark);
	color: var(--white) ;
	border: none;
	box-shadow: none ;
	cursor: pointer;
	font-size: .8rem ;
	border-radius: 0.4rem ;
	padding: 0.5em 1em ;
	margin: 0 1em ; 
	width: auto;
	transition: all 0.3s ;
}

.sched-btn:focus {
	outline: 0 ;
	box-shadow: none ;
	background-color: var(--darker) ;
}

.sched-btn:active:focus {
	outline: 0 ;
	box-shadow: none ;
	background-color: var(--darker) ;
}

.sched-btn:hover	{
	background-color: var(--darker) ;
	color: var(--white);
}

.btn3 {
	color: var(--white) ;
	background-color: var(--dark);
	font-size: 1rem ;
	padding: 0em 0.7em ;
	border-radius: .5rem;
	transition: all .3s ;
	text-decoration: none;
}

.btn3:hover {
	background-color: var(--darker) ;
	color: var(--white) ;
}

@media(max-width: 600px) {

	.planner-con {
		font-size: .8rem ;
		padding: .3em 2em ;
	}

	.planner-con * {
		font-size: .8rem ;
	}

	.planner-clinic {
		flex-direction: column ;
		padding: 1em 0 ;
	}

	.check-appointment {
		padding-left: 2em;
	}

	.sched-btn {
		background-color: var(--dark);
		color: var(--white) ;
		border: none;
		box-shadow: none ;
		cursor: pointer;
		font-size: .8rem ;
		border-radius: 0.4rem ;
		padding: 0.5em 1em ;
		margin: 0 1em ; 
		width: auto;
		transition: all 0.3s ;
	}

	.btn3 {
		color: var(--white) ;
		background-color: var(--dark);
		font-size: 1rem ;
		padding: 0em 0.7em ;
		border-radius: .5rem;
		transition: all .3s ;
		text-decoration: none;
	}
}