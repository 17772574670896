.title-con {
  color: var(--dark) ;
  margin: 0;
  font-family: var(--font) ;
  letter-spacing: 1px ;
  display: inline-block; 
  text-align: left ;
}

.left-title { 
  font-size: 1.2rem ;
  font-weight: bold;
  padding: 0 1em ;
  margin: 0 auto;
}

@media(max-width: 600px)
{ .left-title
  { font-size: 1.1rem ;
    padding: 0 .5em ;
  }

  .title-con
  { margin: 0 .5em 0 ;
  }

}