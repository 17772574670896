.calendar {
	display: flex;
	/*flex-direction: column;*/
	align-items: center;	
}

.calendar-left {
	display: flex;
}

.cal-date {
	border-radius: .5rem;
	cursor: pointer;
	color: var(--dark);
	display: flex;
	flex-direction: column;
	flex-basis: 15% ;
	margin: .2em ;
	padding: 0 .7em .2em ;
}

.cal-date:hover, .cal-time:hover {
	background-color: var(--lightBlue);
}

.cal-date .date-num {
	margin-bottom: 0 ;
	font-size: 1.1rem ;
}

.cal-date .date-month {
	margin-bottom: 0;
	font-size: .8rem ;
	font-weight: bold;
	text-transform: uppercase;
}

.calendar-right {
	display: flex;
	flex-wrap: wrap;
	max-height: 210px ;
}

.calendar-right .cal-time {
	cursor: pointer;
	color: var(--dark);
	font-size: 1rem ;
	margin: .2em ;
	padding: .3em ;
	border-radius: .5rem;
}

.selected, .calendar-right .selected {
	background-color: var(--dark);
	color: var(--white) ;
}

.selected:hover {
	background-color: var(--dark) ;
}


/* Calendar2 */

.cal2 {
	display: flex;
	flex-direction: column;
}


.cal2 .cal2-date {
	display: flex ;
	justify-content: space-between;
	width: 100% ;
}

.cal2-date .cal2-left {
	border-radius: .5rem;
	cursor: pointer;
	color: var(--dark);
	margin: .5em ;
	padding: 0 .2em .2em ;
	font-size: 1rem ;
}

.cal2-date .cal2-right {
	display: flex;
	flex-direction: row ;
	align-items: center;
	/*flex-basis: 80% ;*/
}

.cal2-right .cal2-time {
	background-color: var(--white);
	border: 1px solid var(--dark);
	color: var(--dark);
	border-radius: .5rem;
	cursor: pointer;
	margin: .4em .2em ;
	padding: .5em ;
	font-size: .8rem ;
}

.cal2-right .cal2-time:hover {
	background-color: var(--darkLightBlue);
}

.cal2-right .greyed {
	background-color: lightgray;
	color: white;
	border: none;
	cursor: not-allowed;
}

.cal2-right .greyed:hover {
	background-color: lightgray;
	color: white;
	border: none;
}

.cal2 .select2 {
	background-color: var(--dark);
	color: var(--white) ;
}

.cal2 .select2:hover {
	background-color: var(--dark);
}

@media(max-width: 600px) {
	
	/* Calendar2 */

	.cal2 {
		display: flex;
		flex-direction: row;
		align-items: flex-start ;
		/*justify-content: space-between;*/
	}


	.cal2 .cal2-date {
		display: flex ;
		flex-direction: column;
		justify-content: space-between;
		height: 100% ;
	}

	.cal2-date .cal2-left {
		border-radius: .5rem;
		display : flex;
		flex-direction: column ;
		margin: .3em ;
		padding: 0 .2em .2em ;
		font-size: .8rem ;
	}
	
	.cal2-date .cal2-right {
		display: flex;
		flex-direction: column ;
		font-size: .7rem ;
		align-items	: flex-end;
		height: 100%;
	}

	.cal2-right .cal2-time {
		background-color: var(--white);
		border: 1px solid var(--dark);
		color: var(--dark);
		border-radius: .5rem;
		cursor: pointer;
		margin: .4em .2em ;
		padding: .5em ;
		font-size: .8rem ;
	}

	.cal2-right .greyed {
		background-color: lightgray;
		color: white;
		border: none;
		cursor: not-allowed;
	}

	.cal2 .select2 {
		background-color: var(--dark);
		color: var(--white) ;
	}

	/*.row>* {
		padding: 0;
	}*/
}