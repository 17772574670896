.schedule {
	font-size: .5rem ;
	flex-grow: 1;
}

.schedule * {
	font-family: var(--font);
}

.msg-edit {
	display: flex ;
	padding: 1em ;
}

.msg-edit .btn {
	font-size: .9rem ;
	margin: .5em ;
}

.schedule label {
	font-size: .8rem ;
}