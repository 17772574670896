.topbar {
	display: flex ;
	justify-content: space-between;
	background-color: var(--white) ;
  	color: var(--dark);
  	border-bottom: 1px solid var(--dark);
  	width: 100% ;
}

.logo  {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .5em ;
	/*flex-basis: 15%;*/
}

.logo img {	
	height: 50px;
}

.logo p {
	margin: 0 1em ;
	font-size: 1.2rem ;
	font-family: var(--font);
	color: var(--dark) ;
	font-weight: bold;
}

.right-topbar {
	display: flex; 
	flex-direction: column;
	justify-content: center;
	/*flex-basis: 90%;*/
}

.bar-items {
	display: flex;
	justify-content: flex-end;
}

.bar-item {
	display: flex;
	margin: 0 0.9vw ;
	font-size: 1rem ;
	padding: 0.6em 0.7vw;
	transition: all 0.5s ;
}

.bar-item a {	
	text-decoration: none ;
	color: var(--dark) ;
}

.bar-item:hover {
	background-color: rgb(83, 154, 212, 0.3) ;
}

.item-txt {
	font-size: 1rem ;
	font-family: var(--font);
	margin-left: 0.3em ;
}

@media(max-width: 600px) {
	.topbar {
	  	max-width: 100% ;
	}

	.logo  {
		padding: .2em ;
		/*flex-basis: 15%;*/
	}

	.logo img {	
		height: 40px;
	}

	.logo p {
		margin: 0 .5em ;
		font-size: 1rem ;
	}

	.right-topbar {
	}

	.bar-items {
	}

	.bar-item {
		display: flex;
		margin: 0 0.9vw ;
		font-size: 1rem ;
		padding: 0.6em 0.7vw;
		transition: all 0.5s ;
	}

	.bar-item a {	
		text-decoration: none ;
		color: var(--dark) ;
	}

	.bar-item:hover {
		background-color: rgb(83, 154, 212, 0.3) ;
	}

	.item-txt {
		font-size: 1rem ;
		font-family: var(--font);
		margin-left: 0.3em ;
	}
}
