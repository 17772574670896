.clinic {
	font-family: var(--font);
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 35%;
	/*padding: 1em ;*/
}

.clinic-add {
	margin : 1em 0 ;
	font-size: 1.1rem ;
}

.clinic-btn {
	background-color: var(--dark);
	color: var(--white) ;
	border: none;
	box-shadow: none ;
	cursor: pointer;
	font-size: 1rem ;
	border-radius: 0.4rem ;
	padding: 0.5em 1em ;
	margin: 0 1em ; 
	width: auto;
	transition: all 0.3s ;
}

.clinic-btn:focus {
	outline: 0 ;
	box-shadow: none ;
	background-color: var(--darker) ;
}

.clinic-btn:active:focus {
	outline: 0 ;
	box-shadow: none ;
	background-color: var(--darker) ;
}

.clinic-btn:hover	{
	background-color: var(--darker) ;
	color: var(--white);
}

@media(max-width: 600px) {
	.clinic {
		max-width: 100%;
		padding: 1em ;
	}

	.clinic-add {
		margin : .5em 0 ;
		font-size: .9rem ;
	}

	.clinic-num {
		margin-bottom: .5em ;
	}

	.clinic-btn {
		font-size: .8rem ;
		padding: 0.5em 1em ;
		margin: 0 1em ; 
	}
}